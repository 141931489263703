const colors = require('tailwindcss/colors')
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    fontFamily: {
      sans: [
        'Poppins',
        'ui-sans-serif',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: [
        'ui-serif',
        'Georgia',
        'Cambria',
        '"Times New Roman"',
        'Times',
        'serif',
      ],
      mono: [
        'ui-monospace',
        'SFMono-Regular',
        'Menlo',
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace',
      ],
    },
    container: {
      center: true,
      screens: {
        sm: '100%',
        md: '100%',
        lg: '100%',
        xl: '1440px',
      },
      padding: {
        DEFAULT: '2rem',
        sm: '4rem',
        lg: '6em',
        xl: '8rem',
        '2xl': '8rem',
      },
    },
    colors: {
      transparent: 'rgba(255, 255, 255, 0)',
      current: 'currentColor',
      black: '#000',
      white: '#ffffff',
      purple: {
        border: '#636C88',
        lightest: '#869AD8',
        light: '#5669A6',
        DEFAULT: '#2E3859',
        dark: '#212840',
        darkest: '#1F263B',
        'dark-slide': '#161B2E',
        transparent: '#2E385900',
        input: '#2E385926',
        placeholder: '#2E385935',
        'button-hover': '#787C89',
      },
      yellow: {
        lightest: '#F8C750',
        light: '#FFC63C',
        DEFAULT: '#FAB608',
        dark: '#DB9F07',
      },
      sand: {
        DEFAULT: '#F7F7F4',
        dark: '#DEDEDB',
        darkest: '#CDCDCA',
      },
      gray: {
        light: '#EAEAEA',
        lighter: '#E6E8F2',
        lightest: '#CCCCCC',
        lightestest: '#F4F5FA',
        DEFAULT: '#7F8390',
        subtitle: '#9B959A',
        'terminal-border': '#E3E4E9',
        hr: '#E2E4E8',
        'image-bg': '#EFEFEF',
        'job-card': '#DBDDE6',
        'job-card-light': '#F0F2F5',
      },
      benefits: {
        100: '#f3f3f9',
        200: '#f8f7fa',
        300: '#f3f3f9',
        400: '#e3e5f1',
        500: '#f3f3f9',
        600: '#f3f3f9',
        700: '#d4d6e9',
        800: '#e5e5ef',
      },
      red: {
        ...colors.red,
        'bool-icon-bg': '#F8E6E6',
        'bool-icon-text': '#B55A5A',
      },
      green: {
        ...colors.green,
        'bool-icon-bg': '#E6F8ED',
        'bool-icon-text': '#3B863B',
        DEFAULT: '#3B863B',
      },
    },
    fontSize: {
      '12-18': ['12px', '18px'],
      '12-20': ['12px', '20px'],
      '15-23': ['15px', '23px'],
      '14-24': ['14px', '24px'],
      '16-28': ['16px', '28px'],
      '16-25': ['16px', '25px'],
      '18-27': ['18px', '27px'],
      '18-30': ['18px', '30px'],
      '18-31': ['18px', '31px'],
      '20-30': ['20px', '30px'],
      '22-32': ['22px', '32px'],
      '24-30': ['24px', '30px'],
      '24-31': ['24px', '31px'],
      '24-35': ['24px', '35px'],
      '24-56': ['24px', '56px'],
      '26-35': ['26px', '35px'],
      '26-39': ['26px', '39px'],
      '28-34': ['28px', '34px'],
      '32-42': ['32px', '42px'],
      '35-55': ['35px', '55px'],
      '36-40': ['36px', '40px'],
      '40-55': ['40px', '55px'],
      '40-60': ['40px', '60px'],
      '40-61': ['40px', '61px'],
      '45-55': ['45px', '55px'],
      '60-66': ['60px', '66px'],
      '60-70': ['60px', '70px'],
      '60-80': ['60px', '80px'],
    },
    // Explicitly setting screens because we refer to them in other files
    screens: {
      xs: '480px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1920px',
    },
    extend: {
      minHeight: {
        0: '0',
        '1/3': '33%',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        full: '100%',
        'screen-1/3': '33vh',
        'screen-2/3': '66vh',
        128: '32rem',
      },
      spacing: {
        22: '5.5rem',
        104: '26rem',
        112: '28rem',
        120: '30rem',
        128: '32rem',
        136: '34rem',
        144: '36rem',
        152: '38rem',
        160: '40rem',
        168: '42rem',
        176: '44rem',
        184: '46rem',
        192: '48rem',
      },
      width: {
        '1/8': '12.5%',
        '2/8': '25%',
        '3/8': '37.5%',
        '4/8': '50%',
        '5/8': '62.5%',
        '6/8': '75%',
        '7/8': '87.5%',
      },
      zIndex: {
        '-1': -1,
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    plugin(function ({ addUtilities, theme }) {
      let classes = {}

      const colors = [
        { name: 'white', color: theme('colors.white') },
        { name: 'yellow', color: theme('colors.yellow.DEFAULT') },
        { name: 'blue', color: theme('colors.purple.DEFAULT') },
        { name: 'dark_blue', color: theme('colors.purple.dark') },
      ]

      const pairs = colors
        .map((v, i) => colors.slice(i + 1).map(w => [v, w]))
        .flat()

      pairs.forEach(pair => {
        classes[`.bgg-${pair[0].name}-${pair[1].name}`] = {
          background: `linear-gradient(180deg, ${pair[0].color} 0%,  ${pair[0].color} 50%,  ${pair[1].color} 50%,  ${pair[1].color} 100%)`,
        }
        classes[`.bgg-${pair[1].name}-${pair[0].name}`] = {
          background: `linear-gradient(180deg, ${pair[1].color} 0%,  ${pair[1].color} 50%,  ${pair[0].color} 50%,  ${pair[0].color} 100%)`,
        }
      })
      addUtilities(classes)
    }),
    plugin(function ({ addUtilities, theme }) {
      let classes = {}

      const colors = [
        { name: 'white', color: theme('colors.white') },
        { name: 'yellow', color: theme('colors.yellow.DEFAULT') },
        { name: 'blue', color: theme('colors.purple.DEFAULT') },
        { name: 'dark_blue', color: theme('colors.purple.dark') },
      ]

      const positions = ['25', '50', '75']

      const pairs = colors
        .map((firstColor, i) =>
          colors.slice(i + 1).map(secondColor => [firstColor, secondColor])
        )
        .flat()

      pairs.map(pair => {
        positions.map(position => {
          classes[`.bgg-${pair[0].name}-${pair[1].name}-${position}`] = {
            background: `linear-gradient(180deg, ${pair[0].color} 0%,  ${pair[0].color} ${position}%,  ${pair[1].color} ${position}%,  ${pair[1].color} 100%)`,
          }
          classes[`.bgg-${pair[1].name}-${pair[0].name}-${position}`] = {
            background: `linear-gradient(180deg, ${pair[1].color} 0%,  ${pair[1].color} ${position}%,  ${pair[0].color} ${position}%,  ${pair[0].color} 100%)`,
          }
        })
      })

      addUtilities(classes)
    }),
  ],
}
