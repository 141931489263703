import useSWR from 'swr'

export const useBlogposts = ({ locale, previewToken = null }, options) => {
  const { data, error } = useSWR(
    `/api/blog?locale=${locale}&previewToken=${previewToken}`,
    options
  )

  return {
    blogposts: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useBlogpost = ({ slug, locale, previewToken = null }, options) => {
  const { data, error } = useSWR(
    slug
      ? `/api/blog/${slug}?locale=${locale}&previewToken=${previewToken}`
      : null,
    options
  )
  return {
    blogpost: data,
    isLoading: !error && !data,
    isError: error,
  }
}
