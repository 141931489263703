import moment from 'moment'
import 'moment/min/locales'

const localeFormat = {
  de: 'DD.MM.YYYY',
  en: 'YYYY-MM-DD',
}

const dateDiff = {
  yesterday: {
    de: 'gestern',
    en: 'yesterday',
  },
  today: {
    de: 'heute',
    en: 'today',
  },
}

const getDisplayDate = (date, lang) => {
  moment.locale(lang)

  const momentDate = moment(date)
  return moment({ hours: 0 }).diff(momentDate, 'days') >= 2
    ? momentDate.format(localeFormat[lang])
    : moment({ hours: 0 }).diff(momentDate, 'days') === 1
    ? dateDiff.yesterday[lang]
    : dateDiff.today[lang]
}

export default getDisplayDate
