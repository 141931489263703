import styles from './Hero.module.scss'
import parse from 'html-react-parser'
import cx from 'classnames'

import Media from '../Media'
import CustomLink from '../CustomLink'
import cleanString from '../../../lib/cleanString'
import Section from '../../Section'

export const Hero = ({ content, isSection = true }) => {
  const {
    hero_color: { value: color },
    hero_gradient: gradient,
    hero_gradient_color: gradientColor,
    hero_gradient_position: gradientPosition,
    linkedvideo: linkedVideo,
    mediatype: mediaType,
    uploadedasset: uploadedAsset,
    mediaoverlap: mediaOverlap,
    subtitle: subtitle,
    teaser: teaser,
    title: title,
    link: customLink,
    linktext: customLinkText,
    linktype: customLinkType,
    newtab: customLinkNewTab,
    color: customLinkColor,
  } = content

  return (
    <>
      <Section
        color={color}
        gradient={gradient}
        gradientColor={gradientColor?.value}
        gradientPosition={gradientPosition?.value}
        classNames={cx(styles.sectionWrapper, {
          [styles.noSection]:
            !isSection ||
            (title && !subtitle && !teaser && !mediaType?.value && !customLink),
        })}
      >
        <div
          className={cx(styles.sectionInner, {
            [styles.noSection]: !isSection,
            [styles.section]: isSection,
          })}
        >
          <div
            className={cx(styles.heroLeft, {
              [styles.noSection]: !isSection,
              [styles.section]: isSection,
              'w-full md:w-1/2':
                mediaType?.value && (linkedVideo || uploadedAsset),
              'w-full text-center':
                !mediaType?.value ||
                (mediaType?.value === 'uploaded_asset' && !uploadedAsset) ||
                (mediaType?.value === 'linked_video' && !linkedVideo),
            })}
          >
            {subtitle && (
              <div
                className={cx(styles.teaser, {
                  'text-white': color === 'blue',
                })}
              >
                {parse(subtitle)}
              </div>
            )}
            {title && (
              <h1
                className={cx(styles.headline, {
                  [styles.noSection]: !isSection,
                  [styles.section]: isSection,
                  'text-white': color === 'yellow' || color === 'blue',
                  [styles.noMedia]:
                    mediaType?.value && (linkedVideo || uploadedAsset),
                })}
                dangerouslySetInnerHTML={{ __html: cleanString(title) }}
              />
            )}
            {teaser && <div className={styles.teaser}>{parse(teaser)}</div>}
            {customLink && (
              <CustomLink
                linkType={customLinkType}
                link={customLink}
                text={customLinkText}
                newTab={customLinkNewTab}
                color={customLinkColor.value}
                className="mt-8"
              >
                {customLinkText}
              </CustomLink>
            )}
          </div>
          {mediaType && (uploadedAsset || linkedVideo) && (
            <Media
              className={cx({
                [styles.withOverlap]: mediaOverlap,
                [styles.withoutOverlap]: !mediaOverlap,
                'aspect-video':
                  mediaType?.value === 'linked_video' ||
                  uploadedAsset?.is_video,
              })}
              isSection={false}
              playerProps={{
                muted: true,
                autoplay: true,
                loop: true,
              }}
              imageProps={{
                layout: mediaOverlap ? 'raw' : 'responsive',
                priority: true,
                quality: 100,
                className: 'md:rounded-2xl',
                sizes: `
                (max-width: 1024px) 100vw,
                1440px
                `,
              }}
              content={{
                linkedvideo: linkedVideo,
                mediatype: mediaType,
                uploadedasset: uploadedAsset,
              }}
            />
          )}
        </div>
      </Section>
    </>
  )
}

export default Hero
