import useTranslation from 'next-translate/useTranslation'
import styles from './BlogpostPreviewCard.module.scss'
import { useEffect, useState } from 'react'
import getDisplayDate from '../../lib/displayDate'
import cx from 'classnames'
import { Clock, Edit2 } from 'react-feather'
import ImageComponent from '../Image'
import Link from 'next/link'

export const BlogpostPreviewCard = ({
  className,
  blogpost,
  variant = 'vertical', // 'vertical' or 'horizontal'
  size = 'small', //small or big for horizontal variant
  reversed = false, //reversed for horizontal variant
}) => {
  const { lang, t } = useTranslation('blog')

  const [displayDate, setDisplayDate] = useState('')
  useEffect(() => {
    setDisplayDate(getDisplayDate(blogpost.date, lang))
  }, [blogpost.date, lang])

  if (!blogpost) {
    return null
  }

  return (
    <Link href={`/blog/${blogpost.slug}`} passHref locale={lang}>
      <a rel="noreferrer" className={className}>
        <article
          className={cx(styles.wrapper, styles[variant], {
            [styles.reversed]: reversed,
          })}
        >
          {variant === 'vertical' && (
            <div className={styles.resort}>{blogpost?.resort?.title || ''}</div>
          )}

          {blogpost.hero_image && (
            <div
              className={cx(styles.imageWrapper, styles[variant], styles[size])}
            >
              <ImageComponent
                src={blogpost.hero_image.permalink}
                alt={blogpost.hero_image.alt}
                layout="fill"
                objectFit="cover"
                objectPosition={blogpost.hero_image.focus_css}
              />
            </div>
          )}

          <div className={cx(styles.content, styles[variant], styles[size])}>
            {variant === 'horizontal' && (
              <div className={cx(styles.resort, styles[size])}>
                {blogpost?.resort?.title || ''}
              </div>
            )}

            {blogpost.title && (
              <div className={styles.title}>{blogpost.title}</div>
            )}

            {blogpost.intro && (
              <div className={styles.intro}>
                <div dangerouslySetInnerHTML={{ __html: blogpost.intro }} />
              </div>
            )}

            <div className={styles.infoWrapper}>
              <div className={styles.info}>
                <Clock className={styles.icon} />
                {blogpost.read_time && blogpost.read_time} {t('read_time')}
              </div>

              <div className={styles.info}>
                <Edit2 className={styles.icon} />
                {displayDate}
              </div>
            </div>
          </div>
        </article>
      </a>
    </Link>
  )
}

export default BlogpostPreviewCard
